var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getGroupData.length > 0)?_c('div',{staticClass:"col-12 w-100"},_vm._l((_vm.getGroupData),function(group,groupIndex){return _c('div',{key:groupIndex,staticClass:"w-100"},[(_vm.getGroupProducts(group, _vm.getGroupBy).length > 0)?_c('div',{staticClass:"groupTitle row w-100 m-0 p-0 mb-3"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"m-0 p-0",style:(_vm.groupTitleStyles(_vm.pro.style.groupProductsStyle))},[_vm._v(" "+_vm._s(group)+" ")])])]):_vm._e(),_c('div',{staticClass:"row m-0 p-0 w-100"},_vm._l((_vm.getGroupProducts(group, _vm.getGroupBy)),function(product,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGroupProductsLimitation(index)),expression:"showGroupProductsLimitation(index)"}],key:index,staticClass:"mb-3",class:[
          _vm.isTest && !_vm.isDesktopView
            ? `col-${12 / _vm.pro.productsPerPageMobile}`
            : `col-${12 / _vm.pro.productsPerPageMobile} col-sm-${
                12 / _vm.pro.productsPerPage
              }`,
              _vm.getGroupProducts(group, _vm.getGroupBy).length <= 2 ? 'mx-auto' : null,
        ]},[_c('div',{staticClass:"card shadow-sm d-flex flex-column h-100 mx-auto position-relative",style:(_vm.getCardBackground(_vm.pro.style) +
            _vm.getCardBorderCSS(_vm.pro.style) +
            _vm.getPagePadding(_vm.pro.style) +
            _vm.getFontFamily(_vm.pro.style) +
            _vm.ListProductMaxWidth(
              _vm.isDesktopView ? _vm.pro.productsPerPage : _vm.pro.productsPerPageMobile
            ))},[(_vm.pro.showProductRank && index <= _vm.products.length)?_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"position":"absolute","top":"-2%","left":"50%","transform":"translateX(-50%)","z-index":"100"}},[(_vm.pro.productRankOption[index] && _vm.pro.productRankOption[index].text != '')?_c('p',{staticStyle:{"text-align":"center"},style:(_vm.getProductRankCss(_vm.pro.style))},[_c('span',[_vm._v(_vm._s(_vm.pro.productRankOption[index].text))])]):_vm._e()]):_vm._e(),_c('div',[(
              _vm.showImage(_vm.getProductInfoDetail(
                                    _vm.pro.useProductVariant,
                                    product,
                                    'image'
                                  ))
               
              )?_c('div',{staticStyle:{"height":"100%"}},[(
                  _vm.checkFileType(
                    _vm.getProductInfoDetail(
                      _vm.pro.useProductVariant,
                      product,
                      'image'
                    )
                  ) == 'video'
                )?_c('div',{style:(_vm.ListImageAspectRatio(_vm.pro.style))},[_c('video',{ref:"videoRef",refInFor:true,staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.pro.style) + _vm.getImageTopBorderCSs(_vm.pro.style)),attrs:{"src":`${_vm.getProductInfoDetail(
                    _vm.pro.useProductVariant,
                    product,
                    'image'
                  )}`,"controls":""}})]):_c('div',{style:(_vm.ListImageAspectRatio(_vm.pro.style))},[(
                    _vm.getProductInfoDetail(
                      _vm.pro.useProductVariant,
                      product,
                      'image'
                    ).startsWith('upload')
                  )?_c('LazyImage',{staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.pro.style) + _vm.getImageTopBorderCSs(_vm.pro.style)),attrs:{"src":'/' +
                    _vm.getProductInfoDetail(
                      _vm.pro.useProductVariant,
                      product,
                      'image'
                    ),"alt":""}}):_c('LazyImage',{staticStyle:{"position":"absolute","top":"0","left":"0","bottom":"0","right":"0","height":"100%","width":"100%"},style:(_vm.getImageFit(_vm.pro.style) + _vm.getImageTopBorderCSs(_vm.pro.style)),attrs:{"src":_vm.getProductInfoDetail(
                      _vm.pro.useProductVariant,
                      product,
                      'image'
                    ),"alt":""}})],1)]):_vm._e()]),_c('div',{staticClass:"mt-2 flex-grow-1",style:(_vm.getPaddingCss(_vm.pro.style))},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"px-3"},[_c('p',{style:(_vm.getTitleStyles(_vm.pro.style))},[_vm._v(" "+_vm._s(_vm.getProductInfoDetail( _vm.pro.useProductVariant, product, "title" ))+" ")]),(product.rating && _vm.isJudgeMeInstalled)?_c('div',{staticClass:"Review",style:(_vm.getPriceColor(_vm.pro.style)),domProps:{"innerHTML":_vm._s(product.rating)}}):_vm._e()]),_c('div',{staticClass:"d-flex flex-column justify-content-between flex-grow-1"},[_c('div',{},[(!_vm.pro.hideDescription)?_c('div',[_c('div',{staticClass:"d-flex align-items-start mt-1",style:(_vm.limitProductDescription(
                          _vm.pro.limitDescription,
                          product.readMore
                        ))},[(
                          _vm.pro.useBulletDescription &&
                          product.bullet_description &&
                          product.bullet_description.length
                        )?_c('div',_vm._l((product.bullet_description),function(bulletPoint,index2){return _c('div',{key:index2},[_c('div',{staticClass:"d-flex align-items-start mt-1",style:(_vm.getBulletStyles(_vm.pro.style))},[_c('div',{staticClass:"mr-3"},[_c('i',{staticClass:"fa",class:_vm.pro.selectedBulletIcon})]),_c('span',{staticStyle:{"text-align":"start"}},[_vm._v(_vm._s(bulletPoint))])])])}),0):_c('div',{staticClass:"w-100",style:(`font-family:${_vm.pro.style.fontFamily};`),domProps:{"innerHTML":_vm._s(product.description)}})]),(
                        _vm.pro.limitDescription &&
                        product.description &&
                        product.description.split(' ').join('').length > 150 && false
                      )?_c('div',{},[_c('button',{staticClass:"btn",class:product.readMore ? 'mt-2' : 'mt-2',style:(_vm.getPriceColor(_vm.pro.style)),on:{"click":function($event){product.readMore = !product.readMore}}},[_c('span',[_vm._v(_vm._s(product.readMore ? _vm.translateText("Less info") : _vm.translateText("More info")))])])]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex align-items-center",style:('justify-content:' + _vm.pro.style.textAlign + ';')},[(product.price && !_vm.isHidePrice)?_c('p',{staticClass:"m-0 p-0 mr-2",style:(_vm.getPriceColor(_vm.pro.style))},[(_vm.CurrencySign && _vm.currencyPos == 'left')?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e(),_vm._v(_vm._s(_vm.getProductInfoDetail( _vm.pro.useProductVariant, product, "price" ))),(_vm.CurrencySign && _vm.currencyPos == 'right')?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e()]):_vm._e(),(
                      product.compare_at_price &&
                      product.price !== product.compare_at_price &&
                      !_vm.isHidePrice
                    )?_c('p',{staticClass:"m-0 p-0",style:(_vm.getPriceColor(_vm.pro.style))},[_c('s',[(_vm.CurrencySign && _vm.currencyPos == 'left')?_c('span',{class:_vm.currencySpace ? 'mr-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e(),_vm._v(_vm._s(product.compare_at_price)),(_vm.CurrencySign && _vm.currencyPos == 'right')?_c('span',{class:_vm.currencySpace ? 'ml-1' : null},[_vm._v(_vm._s(_vm.CurrencySign))]):_vm._e()])]):_vm._e()]),(product.detail_link)?_c('div',{staticClass:"mt-3"},[(
                      _vm.pro.useProductVariant && product && product.variants.length > 0
                    )?_c('div',{staticClass:"pb-2"},[_c('VaraintSelectComp',{attrs:{"pro":_vm.pro,"product":product}})],1):_vm._e(),(_vm.pro.useAddToCart)?_c('button',{staticClass:"ButtonHover",style:(_vm.getProductButtonCss(_vm.pro.style)),attrs:{"disabled":_vm.disableAddtoCartBtn},on:{"click":function($event){return _vm.AddToCart(product, index)}}},[(!product.loading)?_c('span',[_vm._v(_vm._s(_vm.pro.useAddToCartText || "Add to Cart"))]):_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])]):_c('button',{staticClass:"ButtonHover",style:(_vm.getProductButtonCss(_vm.pro.style)),on:{"click":function($event){return _vm.goToURLClicked(product.detail_link,product.id)}}},[_vm._v(" "+_vm._s(_vm.pro.buttonText)+" ")])]):_vm._e()])])])])])])}),0)])}),0):_c('div',{staticClass:"col-12 w-100"},[_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h5',{staticStyle:{"text-align":"center"}},[_vm._v("No groups selected!")]),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" Please select groups for this component to work ")])])
}]

export { render, staticRenderFns }